import React from "react";
import styles from "./Home.module.css";
import Header from "../Header/Header";
import ProjectBox from "../Components/ProjectBox";

const PROJECTS = [
  {
    id: 1,
    img: "https://i.imgur.com/iOfi9Ia.png",
    img_alt: "2D Game Engine",
    name: "2D Game Engine",
    subtitle: "Software Engineering",
    description: "ECS | Systems | Events | Rendering",
    link: "/projects/game-engine",
    people: "1",
    duration: "3 months",
  },
  {
    id: 2,
    img: "https://i.imgur.com/8v0jsq2.gif",
    img_alt: "Dialogue System",
    name: "Dialogue System",
    subtitle: "Gameplay Programming",
    description: "Core Systems | UI | Editor",
    link: "/projects/dialogue-system",
    people: "1",
    duration: "4 months",
  },
]

function Home() {
  return (
    <React.Fragment>
      <Header />
      <div className={styles.box}>
        <div className={styles.div1}>
          <h2>Hi! I'm</h2>
          <h1>Tessla Muir</h1>
        </div>
        <div className={styles.div2}>
          <p>I am an experienced gameplay programmer who enjoys mixing my creative and technical sides - a passion for seamlessly blending technical prowess and creative flair to craft helpful tools & captivating user interfaces. Adept at collaborating with cross-functional teams, I bring a unique blend to elevate player experiences.</p>
        </div>
      </div>
      <div className={styles.list}>
        {PROJECTS.map((project) => (
          <ProjectBox
            back_link={project.back_link}
            img={project.img}
            img_alt={project.img_alt}
            name={project.name}
            subtitle={project.subtitle}
            description={project.description}
            link={project.link}
            src={project.src}
            imgs={project.imgs}
            main_img={project.main_img}
            duration={project.duration}
            people={project.people}
          />
        ))}
      </div>
      {/* <ProjectSection /> */}
    </React.Fragment>
  );
}

export default Home;
