import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";

function Header() {
  return (
    <header className={styles.header}>
      <nav>
        <div>
          <DropdownMenu></DropdownMenu>
        </div>
        <NavLink activeClassName={styles.active} to="/about">
          <img
            src="https://i.imgur.com/NKrNJSe.png"
            alt="logo"
            className={styles.logo}
          />
        </NavLink>
        <ul>
          <li>
            <NavLink activeClassName={styles.active} to="/about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.active} to="/experience">
              Experience
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.active} to="/projects">
              Projects
            </NavLink>
          </li>
        </ul>

        <div className={styles.contacts}>
          <a href="https://www.linkedin.com/in/tessla-muir/">
            <img
              src="https://i.imgur.com/InIwWO1.png"
              alt="LinkedIn"
              className={styles.img}
            />
          </a>
          <a href="https://github.com/tessla-muir">
            <img
              src="https://i.imgur.com/iz9cVTV.png"
              alt="Github"
              className={styles.img}
            />
          </a>
          <a href="https://tessla-muir.itch.io/">
            <img
              src="https://i.imgur.com/tByIuCD.png"
              alt="Itch.io"
              className={styles.img}
            />
          </a>
          <a href="https://drive.google.com/file/d/1klLw4D-yrACI8eAnQKPM8JEYha8DNq-X/view?usp=sharing"
            className={styles.resume}>
            Resume
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
