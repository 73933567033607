import { Dropdown } from "react-bootstrap";
import styles from "./DropdownMenu.module.css";

function DropdownMenu() {
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-autoclose-true" className={styles.toggle} variant="dark">
        <img src="https://i.imgur.com/xSGbkDI.png" alt="Menu" className={styles.img}/>
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.menu} variant="dark">
        <Dropdown.Item href="/">About</Dropdown.Item>
        <Dropdown.Divider color="white"/>
        <Dropdown.Item href="/experience">Experience</Dropdown.Item>
        <Dropdown.Divider color="white"/>
        <Dropdown.Item href="/projects">Projects</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownMenu;
