import React from "react";
import { Link } from "react-router-dom";
import styles from "./ProjectBox.module.css";

function ProjectBox(props) {
  return (
    <Link role="button" to={props.link} className={styles.link}>
      <div className={styles.box}>

        <div className={styles.imageWrapper}>
          <img src={props.img} alt={props.img_alt} />
          <div className={styles.overlay}>
            <div className={styles.img_box}>
              <img src="https://i.imgur.com/87y23SF.png" alt="" className={styles.img}/>
              <h5>{props.people}</h5>
            </div>
            <div className={styles.img_box}>
              <img src="https://i.imgur.com/UKFDU7n.png" alt="" className={styles.img}/>
              <h5>{props.duration}</h5>
            </div>
          </div>
        </div>

        <div className={styles.text}>
          <div className={styles.text_inner}>
            <h2>{props.name}</h2>
            <img src="https://i.imgur.com/nGgmsF8.png" alt=""/>
          </div>
          <h4>{props.subtitle}</h4>
          <p>{props.description}</p>
        </div>

      </div>
    </Link>
  );
}

export default ProjectBox;
