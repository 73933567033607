import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./App.module.css";

import Home from "./Pages/Home";

const About = React.lazy(() => import("./Pages/About"));
const Projects = React.lazy(() => import("./Pages/Projects"));
const ProjectDetails = React.lazy(() => import("./Pages/ProjectDetails"));
const NotFound = React.lazy(() => import("./Pages/NotFound"));

const EDUCATION = [
  {
    img: "",
    id: 1,
    title: "Master's in Game Development",
    subtitle: "Rochester Institute of Technology",
    subtitle2: "Rochester, NY | August, 2023 - May, 2025",
    description:
      "",
  },
  {
    img: "",
    id: 2,
    title: "Bachelor's in Computer Science",
    subtitle: "West Virginia University",
    subtitle2: "Morgantown, WV | August, 2019 - May, 2023",
    description:
      "GPA: 3.97, Minor in Mathematics",
  },
];
const EXPERIENCE = [
  {
    img: "",
    id: 1,
    title: "MonRiverGames",
    subtitle: "Game Designer & Developer",
    subtitle2: "Morgantown, WV | August, 2022 - June, 2023",
    description: "Designed & implemented complex game mechanics and user interfaces on multiple platforms. Architected core game systems to optimize functionality and enhance the gameplay experience.",
  },
  {
    img: "",
    id: 2,
    title: "USAA",
    subtitle: "Software Engineer Intern",
    subtitle2: "San Antonio, TX | May, 2022 - August, 2022",
    description:
      "Improved and extended object-oriented legacy code for credit card services with RESTful APIs. Utilized version control and test-driven development in an Agile environment.",
  },
];
const CERTIFICATIONS = [
  {
    img: "https://scrumorg-website-prod.s3.amazonaws.com/drupal/inline-images/2022-09/asset_44psmi_0.png",
    id: 2,
    title: "Professional Scrum Master I",
    subtitle: "Scrum.org",
    subtitle2: "August 2022",
    description: "Demonstrates a fundamental level of Scrum mastery. PSM I holders have a consistent terminology and approach to Scrum.",
  },
];
const DESIGN_PROJECTS = [
  {
    id: 1,
    img: "https://i.imgur.com/fnKjfnJ.png",
    img_alt: "Kostroma Model",
    name: "Character Design",
    subtitle: "Kostroma: The First Rusalka",
    description: "Character Design for Slavic Goddess in SMITE",
    link: "/projects/smite-kostroma",
    people: "1",
    duration: "2 months",
  },
  {
    id: 2,
    img: "https://i.imgur.com/t2hTOBC.png",
    img_alt: "SMITE",
    name: "Combat Critique",
    subtitle: "SMITE Shields",
    description: "Critique of SMITE's Shields",
    link: "/projects/smite-critique-shields",
    people: "1",
    duration: "3 weeks",
  },
];
const GAMES_PROJECTS = [
  {
    id: 1,
    img: "https://i.imgur.com/iOfi9Ia.png",
    img_alt: "2D Game Engine",
    name: "2D Game Engine",
    subtitle: "Software Engineering",
    description: "ECS | Systems | Events | Rendering",
    link: "/projects/game-engine",
    people: "1",
    duration: "3 months",
  },
  {
    id: 2,
    img: "https://i.imgur.com/8v0jsq2.gif",
    img_alt: "Dialogue System",
    name: "Dialogue System",
    subtitle: "Gameplay Programming",
    description: "Core Systems | Tools | Editor",
    link: "/projects/dialogue-system",
    people: "1",
    duration: "4 months",
  },
  {
    id: 5,
    img: "https://i.imgur.com/NmYulew.png",
    img_alt: "Raycasting Engine",
    name: "Raycasting Engine",
    subtitle: "Software Engineering",
    description: "Rendering | Raycasting | Projection",
    link: "/projects/raycasting-engine",
    people: "2",
    duration: "1 month",
  },
  {
    id: 3,
    img: "https://i.imgur.com/8FgL6D0.gif",
    img_alt: "Untitled Fish Game",
    name: "Untitled Fish Game",
    subtitle: "Gameplay Programming",
    description: "Game Project | Scrum | Unity",
    link: "/projects/untitled-fish-game",
    people: "4",
    duration: "5 months",
  },
  // {
  //   id: 4,
  //   img: "https://i.imgur.com/tuNLYSm.gif",
  //   img_alt: "Bongo Coots",
  //   name: "Bongo Coots",
  //   subtitle: "Gameplay Programming",
  //   description: "Game Jam | Unity",
  //   link: "/projects/bongo-coots",
  //   people: "3",
  //   duration: "2 weeks",
  // },
  // {
  //   id: 4,
  //   img: "https://i.imgur.com/lZLJBl1.jpg",
  //   img_alt: "Golden Girls",
  //   name: "Golden Trivia",
  //   subtitle: "Gameplay Programming",
  //   description: "UI | Scriptable Objects | Unity",
  //   link: "/projects/golden-trivia",
  //   people: "1",
  //   duration: "5 days",
  // },
];

function App() {
  return (
    <div className={styles.app}>
      <Suspense fallback={<p className="centered">Loading...</p>}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/about" />
          </Route>
          <Route path="/about" exact>
            <Home projects={GAMES_PROJECTS} />
          </Route>
          <Route path="/experience" exact>
            <About
              education={EDUCATION}
              experience={EXPERIENCE}
              courses={CERTIFICATIONS}
            />
          </Route>
          <Route path="/projects" exact>
            <Redirect to="/projects/game_programming" />
          </Route>
          <Route path="/projects/game_programming" exact>
            <Projects title="Game Projects" projects={GAMES_PROJECTS} />
          </Route>
          <Route path="/projects/game_design" exact>
            <Projects title="Design Projects" projects={DESIGN_PROJECTS} />
          </Route>
          <Route path="/projects/game-engine" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="2D Game Engine"
              description="Space Invaders"
              skills="ECS | Systems | Events | Rendering"
              github_link="https://github.com/tessla-muir/game-engine"
              itch_link=""
              explanations={[
                { id: 0, h: "Overview" },
                {
                  id: 1, p: (
                    <span>
                      I developed a custom 2D game engine from scratch using C++ and SDL, designed to support the creation of 2D games with a focus on performance and flexibility. The engine employs an <strong>Entity-Component-System (ECS)</strong> architecture, which facilitates efficient and modular game development.
                    </span>
                  )
                },
                { id: 2, h: "Key Features" },
                {
                  id: 3, ul: [
                    {
                      id: 31, li: (
                        <span>
                          <strong>Entity-Component-System (ECS) Architecture:</strong> The ECS model enhances flexibility and scalability by decoupling game logic from game entities. This allows for easier management and expansion of game features.
                        </span>
                      )
                    },
                    {
                      id: 32, li: (
                        <span>
                          <strong>Entity Tagging and Grouping:</strong> Supports tagging and grouping of entities, allowing for efficient management and querying of related game objects.
                        </span>
                      )
                    },
                    {
                      id: 33, li: (
                        <span>
                          <strong>Comprehensive System Implementation:</strong> Designed and integrated systems for rendering, animation, movement, collision detection, damage handling, lifetime management, scoring, and user interface.
                        </span>
                      )
                    },
                    {
                      id: 33, li: (
                        <span>
                          <strong>Persistent Event Handling:</strong> Implements a robust event handling system that ensures smooth communication between different parts of the engine, improving the responsiveness and interactivity of the games developed with it.
                        </span>
                      )
                    },
                    {
                      id: 34, li: (
                        <span>
                          <strong>Performance Optimization:</strong> Improved cache efficiency and reduced memory fragmentation by packing components into contiguous
                          data structures and using efficient indexing and resizing techniques.
                        </span>
                      )
                    },
                    {
                      id: 35, li: (
                        <span>
                          <strong>Asset Management System:</strong> Managed game assets such as sprites and fonts, facilitating easy loading, unloading, and accessing of resources
                        </span>
                      )
                    },
                    {
                      id: 36, li: (
                        <span>
                          <strong>Developer Tools:</strong> Integrated a logging system, debugger, and unit testing to ensure reliability and facilitate troubleshooting.
                        </span>
                      )
                    }
                  ]
                },
                { id: 4, h: "Demonstration" },
                {
                  id: 5, p: (
                    <span>
                      Using this game engine, I developed a Space Invaders-like game that showcases the engine’s capabilities. The game features smooth animations, responsive controls, and dynamic gameplay, demonstrating the effectiveness of the ECS architecture and the robustness of the systems implemented.
                    </span>
                  )
                },
                { id: 21, src: "https://i.imgur.com/ModBfDa.png" },
                { id: 6, h: "Conclusion" },
                {
                  id: 7, p: (
                    <span>
                      This game engine project highlights my ability to design and implement complex systems, optimize performance, and create flexible, scalable software solutions. It showcases my proficiency in C++ and SDL, as well as my understanding of advanced software engineering principles.
                    </span>
                  )
                }
              ]}
            />
          </Route>
          <Route path="/projects/raycasting-engine" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="Raycasting Engine"
              description="Wolfenstein 3D-style Raycasting"
              skills="Rendering | Raycasting | Projection"
              github_link="https://github.com/tessla-muir/raycasting-engine"
              itch_link=""
              explanations={[
                { id: 0, h: "Overview" },
                { id: 1, p: "A peer and I developed a raycasting engine inspired by the Wolfenstein 3D game, created from scratch using SDL and C. This engine generates a 3D projection from a 2D space by using raycasting to create depth-of-field effects and render sprites." },
                { id: 2, h: "Minimap" },
                { id: 3, p: "The minimap shows the player's current location and their field of view, determined by raycasting (red area). It also displays the wall boundaries (white) and sprites (yellow boxes). The minimap updates dynamically as the player moves around the environment." },
                { id: 31, src: "https://i.imgur.com/nbpZimX.png" },
                { id: 5, h: "3D Projection" },
                { id: 6, p: "We utilized similar triangles to create depth when rendering the walls. Rays are cast from the player's current position, and each pixel column of the wall sprite is adjusted to create depth. The visual below demonstrates the math behind this process." },
                { id: 7, src: "https://i.imgur.com/yAaXfM2.png" },
                { id: 8, p: "Rendering sprites follows a similar process, except that sprites maintain a consistent height since they don't have depth. The distance from the player to each sprite ensures the sprites are rendered in the correct order. Additionally, sprites are only rendered when they are visible to the player." },
                { id: 9, h: "Conclusion" },
                { id: 10, p: "This project showcases our ability to create complex graphical systems from scratch, demonstrating proficiency in SDL, C programming, and mathematical concepts for game development. The raycasting engine not only highlights our technical skills but also our ability to work collaboratively on challenging tasks. This experience has further solidified our understanding of 3D rendering and game engine mechanics, preparing us for more advanced projects in the future." }
              ]}
              imgs={[
                { id: 2, src: "https://i.imgur.com/NmYulew.png" },
                { id: 3, src: "https://i.imgur.com/87N5cS4.png" },
                { id: 1, src: "https://i.imgur.com/2ZAEJWA.png" },
              ]}
            />
          </Route>
          <Route path="/projects/dialogue-system" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="Dialogue System"
              description="Game Development"
              skills="Editor Creation | Dialogue Gameplay | UI"
              github_link="https://github.com/tessla-muir/Dialogue-System"
              itch_link="https://tessla-muir.itch.io/dialogue-system"
              explanations={[
                { id: 0, h: "Overview" },
                { id: 1, p: "I developed a robust dialogue system in Unity, designed to have interactive conversations between players and NPCs. Built with a focus on flexibility and ease of use, the system supports complex branching dialogues and integrates seamlessly with other game systems." },
                { id: 2, h: "Key Features" },
                {
                  id: 3, ul: [
                    {
                      id: 31, li: (
                        <span>
                          Implemented a flexible dialogue node system supporting multiple conversation routes.
                        </span>
                      )
                    },
                    {
                      id: 32, li: (
                        <span>
                          Designed PlayerConversant and AIConversant to manage dialogue flow and interaction.
                        </span>
                      )
                    },
                    {
                      id: 33, li: (
                        <span>
                          Integrated conditional logic for dialogue node visibility based on game state.
                        </span>
                      )
                    },
                    {
                      id: 33, li: (
                        <span>
                          Developed an intuitive editor tool for non-technical users to create and manage dialogue nodes.
                        </span>
                      )
                    },
                    {
                      id: 34, li: (
                        <span>
                          UI/UX design ensuring responsiveness across all desktop resolutions and supporting text and choice-based dialogue formats.
                        </span>
                      )
                    },
                  ]
                },
                { id: 4, h: "Editor Tool" },
                { id: 5, p: "The editor tool allows users to visually create, edit, and delete dialogue nodes. It features bezier curves for node relationships, real-time node color indication for player and AI speech, and seamless integration with Unity's inspector for configuring dialogue actions." },
                { id: 6, src: "https://i.imgur.com/MoMFIeL.png" },
                { id: 7, h: "Application in Games" },
                { id: 8, p: "This dialogue system has been successfully integrated into three different games, showcasing its versatility and adaptability:" },
                {
                  id: 9, ul: [
                    {
                      id: 91, li: (
                        <span>
                          <a href="https://docs.google.com/document/d/1Jpz-vqOugcW31bOnY-Pb7TLIYIiEFskjXCyfdmfOHVA/edit?usp=sharing" target="_blank" rel="noreferrer">
                            The Long Hallway
                          </a> - Utilized for branching storylines and character-driven dialogues, enhancing player immersion and storytelling.
                        </span>
                      )
                    },
                    {
                      id: 92, li: (
                        <span>
                          <a href="https://docs.google.com/document/d/1K8ir2EVsTrkE0diwToQDJZSQM9B30uWACVE5fl8bX3o/edit?usp=sharing" target="_blank" rel="noreferrer">
                            Fantasy Scheme
                          </a> - Integrated and expanded upon for the main mechanic of RPG text-battle (similar to Pokemon).
                        </span>
                      )
                    },
                    {
                      id: 93, li: (
                        <span>
                          <a href="https://www.monrivergames.com/onemanstrash" target="_blank" rel="noreferrer">
                            One Man's Trash
                          </a> - Implemented to enhance NPC interactions and quest dialogues, providing players with meaningful choices and narrative depth.
                        </span>
                      )
                    },
                  ]
                },
                { id: 10, h: "Technical Details" },
                { id: 11, p: "The dialogue system utilizes Unity's GameObject and ScriptableObject architecture for node management. Dialogue nodes are dynamically linked based on player choices, with support for complex branching and conditional execution based on game variables." },
                { id: 12, src: "https://i.imgur.com/4SQV5GL.png" },
                { id: 13, h: "Conclusion" },
                { id: 14, p: "This project highlights my ability in Unity development, UI/UX design, and system architecture. It demonstrates the ability to create intuitive tools for non-programmers while delivering robust functionality for game dialogue management." }
              ]}
              imgs={[
                { id: 2, src: "https://i.imgur.com/OoXakwG.png" },
                { id: 3, src: "https://i.imgur.com/SAui1Qs.png" },
                { id: 1, src: "https://i.imgur.com/cp6xzKN.jpg" },
                { id: 4, src: "https://i.imgur.com/kXxazAU.jpg" }
              ]}
            />
          </Route>
          <Route path="/projects/untitled-fish-game" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="Untitled Fish Game"
              description="Game Development"
              skills="Game Project | Scrum | Unity"
              github_link="https://github.com/Jade777777/Fish-Ilinx"
              itch_link="https://jaredgoronkin.itch.io/fish-game"
              explanations={[
                { id: 1, p: "Untitled Fish Game is a 2.5D side-scrolling aquatic adventure. Made in Unity on a team of four people. My peers and I developed this game using Scrum and Agile principles over the last five months. I acted as a gameplay programmer for the core systems like game and music management. I also individually designed and implemented all the different UI menus." },
                { id: 2, p: "Our team worked in 2-week sprints. We went through all the different Scrum ceremonies: sprint planning, standups, reviews, and retrospectives. Each sprint had a polished release build and release notes. The team actively maintained the GDD, which also contains information on all our sprint artifacts. See it below." },
                { id: 3, link: "https://docs.google.com/document/d/16BEwFqIxFz9uIHeBiJCgbmLmM6vjve-fC_E15LUF4sY/edit?usp=sharing", p: "Game Design Document" },
                { id: 4, link: "https://docs.google.com/document/d/1Z18IqloAkRuCAMyTEEwcfUCYAL2T6q43kIEPJGotF7E/edit?usp=sharing", p: "Sprint Planning & Retrospectives" },
                { id: 5, link: "https://docs.google.com/presentation/d/1tPoi29b4fZBx8IfTJxzaLMPOH6fIJsRrRuUr_8i76zo/edit?usp=sharing", p: "Final Pitch Presentation" },
                { id: 6, link: "https://docs.google.com/document/d/1Ro0aAzOSaXwbWd5im6GXTCD_RO5c50qoiuomqvfEFUI/edit?usp=sharing", p: "Postmortem" },
                { id: 6, p: "One of my favorite additions is the credits screen. Not only did I enjoy designing the credits, but I was happy with my implementation. Here, the credits auto-scroll, or the user can manually drag (which pauses auto-scroll). The UI pulls all the values from a text file for easy updating and helps avoid git issues. It was my first time putting together files and UI; I am glad it worked well!" },
                { id: 7, src: "https://i.imgur.com/XcADsqe.png" }
              ]}
              imgs={[
                { id: 1, src: "https://i.imgur.com/IiCuwgX.png" },
                { id: 2, src: "https://i.imgur.com/VY1RDLb.png" },
                { id: 3, src: "https://i.imgur.com/4gT0UJj.png" },
                { id: 4, src: "https://i.imgur.com/lppSC8x.png" },
              ]}
            />
          </Route>
          <Route path="/projects/bongo-coots" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="Bongo Coots"
              description="Rhythm Game"
              skills="Ludwig 2023 Game Jam | Unity"
              github_link="https://github.com/tessla-muir/Coots_Game"
              explanations={[
                {
                  id: 1, p: "It was developed in a team of two for a game jam, centered on cats. The game idea & design was my own: a bongo-cat-style rhythm game. We collaboratively implemented the rhythm game mechanics. It procedurally generates notes on any song given its bpm. The game supports multiple difficulties which fluctuate in likelihood of skips, single notes, and double notes. Individually designed and implemented the UI."
                }
              ]}
              imgs={[
                { id: 1, src: "https://i.imgur.com/ogDwl4N.png" },
                { id: 2, src: "https://i.imgur.com/072tg3M.png" },
              ]}
              itch_frame="https://itch.io/embed/1944166?linkback=true&amp;bg_color=263036&amp;fg_color=eeeeee&amp;link_color=6ccba2&amp;border_color=363636"
              itch_link="https://tessla-muir.itch.io/coots-game"
              itch_description="Bongo Coots by Tessla Muir"
            />
          </Route>
          <Route path="/projects/golden-trivia" exact>
            <ProjectDetails
              back_link="/game_programming"
              name="Golden Trivia"
              description="Timed Trivia Game"
              skills="UI | Scriptable Objects | Unity"
              github_link="https://github.com/tessla-muir/Golden_Trivia"
              explanations={[
                { id: 1, p: "Golden Trivia is a timed trivia game about The Golden Girls TV show. Test your knowledge of show trivia with four different levels of difficulty from easy to fanatic. Additional trivia quizzes are on the actors and information surrounding the show. Make the Golden Girls Bea proud of your knowledge! This is an independent project where I learned about scriptable objects, UI, and layout groups in this project." }
              ]}
              imgs={[
                { id: 1, src: "https://i.imgur.com/Rnd9bjS.jpg" },
                { id: 2, src: "https://i.imgur.com/lZLJBl1.jpg" },
              ]}
              itch_frame="https://itch.io/embed/1769991?linkback=true&amp;bg_color=263036&amp;fg_color=eeeeee&amp;link_color=6ccba2&amp;border_color=363636"
              itch_link="https://tessla-muir.itch.io/golden-trivia"
              itch_description="Golden Trivia by Tessla Muir"
            />
          </Route>
          <Route path="/projects/smite-kostroma" exact>
            <ProjectDetails
              back_link="/game_design"
              name="Kostroma"
              description="The First Rusalka"
              skills="God Design for SMITE"
              explanations={[
                { id: 1, p: "" }
              ]}
            />
          </Route>
          <Route path="/projects/smite-critique-shields" exact>
            <ProjectDetails
              back_link="/game_design"
              name="SMITE Shields"
              description="Game Combat Critique"
              skills=""
              explanations={[
                { id: 1, p: "" }
              ]}
            />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
